<template>
  <div v-if="category">
    <div v-if="!loading">
      <v-card class="elevation-0">
        <v-breadcrumbs v-if="!displayXS" :items="breadcrumbs"/>

        <v-card-title v-else class="pa-0">
          <v-btn
            :to="linkBack"
            class="mr-3"
            icon
            large
          >
            <v-icon>arrow_back</v-icon>
          </v-btn>
          {{ selectedProject.name }}
        </v-card-title>

        <v-card :style="background" class="mt-3" dark flat>
          <v-card-title class="pb-0">
            {{ category.name }}
            <v-spacer/>
            <v-btn v-if="setAbility('ADMIN')"
                   icon
                   @click="$refs.confirmationDialog.open(category)">
              <v-icon color="white">
                delete
              </v-icon>
            </v-btn>
            <v-btn
              v-if="setAbility('ADMIN')"
              icon
              @click="$refs.copyCategoryDialog.open(category)">
              <v-icon color="white">
                content_copy
              </v-icon>
            </v-btn>
            <v-btn
              v-if="setAbility('ADMIN')"
              icon
              @click="$refs.categoryDetailsDialog.edit(category)">
              <v-icon color="white">
                edit
              </v-icon>
            </v-btn>
          </v-card-title>

          <div class="mx-4"><strong>{{ category.type }}</strong></div>

          <v-card-text
            v-if="category.description"
            class="white--text"
            v-html="category.description"/>

          <v-card-actions class="px-4 pb-3">
            <v-spacer/>
            <v-btn text @click="$refs.userListDialog.open()">
              <v-icon left>people_alt</v-icon>
              {{ userList.length }}
            </v-btn>
          </v-card-actions>
          <user-list-dialog
            ref="userListDialog"
            :user-list="userList"
          />
        </v-card>

        <div class="mt-2 d-flex">
          <div v-if="filters && filters.length > 0">
            <v-icon color="blue darken-2">tag</v-icon>
            <v-chip
              v-for="filter in filters"
              :outlined="!filter.active"
              class="ml-2 my-1"
              label
              @click="filter.active = !filter.active">
              {{ filter.value }}
            </v-chip>
          </div>
          <v-spacer/>
          <v-btn color="primary" outlined @click="() => $refs.docUploadDialog.open()">
            <v-icon class="mr-2">mdi-file-plus</v-icon>
            {{ $t('uploadFile') }}
          </v-btn>
        </div>

      </v-card>
      <div v-if="category.hasProcess">
        <div class="mt-5">Процесс загрузки данных</div>
        <v-card-actions class="pa-0">
          <v-progress-linear
            :value="progress"
            dark
            height="25"
          >
            <strong>{{ progress }}%</strong>
          </v-progress-linear>

          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>info</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <div v-for="(value, key) in dataByStatus">
                  <b>{{ $t(`adStatus.${key}`) }}</b>: {{ value.length }}
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-card-actions>
      </div>

      <v-card
        v-if="issues && issues.length"
        class="mt-2"
        outlined
      >
        <v-card-title class="pb-1 pt-2">
          <span class="subtitle-1 pb-1">{{ $t('issues') }}</span>
          <v-spacer/>
          <v-btn
            text
            @click="goToIssues"
          >
            {{ $t('toIssues') }}
          </v-btn>
        </v-card-title>

        <v-list dense subheader>
          <template v-for="(issue, index) in issues">
            <v-list-item :key="`issue-${index}`" @click="openIssue(issue, $event)">
              <v-list-item-avatar>
                <v-avatar :color="issue.status.color" class="mx-3" size="20"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ issue.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ getUserFullName(issue.performer) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>

      <simple-data-row
        v-for="item in filteredDataList"
        :key="item.id"
        :item="item"
        :showStatus="category.hasProcess"
        class="mt-3"
        simple
        @open="$refs.viewDialog.open"
      />
    </div>
    <v-skeleton-loader
      v-else
      :loading="loading"
      class="mt-6"
      max-height="150"
      type="image"
    />
    <category-details-dialog
      ref="categoryDetailsDialog"
      @onSave="onSave"
    />
    <copy-category-dialog ref="copyCategoryDialog"/>
    <confirmation-dialog
      ref="confirmationDialog"
      :title="$t('removeConfirmation')"
      @confirm="removeCategory"
    />

    <abstract-data-view-dialog ref="viewDialog"/>

    <doc-upload-dialog ref="docUploadDialog" :defaultCategory="this.category" @onSave="loadDataList"/>
  </div>

</template>

<script>
import SimpleDataRow from '../utils/SimpleDataRow'
import CategoryDetailsDialog from './CategoryDetailsDialog'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import ConfirmationDialog from '../utils/ConfirmationDialog'
import messages from '@/componet-locale/category-view/messages'
import UserListDialog from '@/components/utils/UserListDialog'
import AbstractDataViewDialog from '@/components/utils/AbstractDataViewDialog'
import CopyCategoryDialog from '@/components/category/CopyCategoryDialog'
import DocUploadDialog from '@/components/doc/DocUploadDialog.vue'
import { EventBus } from '@/event-bus'

export default {
  name: 'CategoryViewer',
  components: {
    DocUploadDialog,
    CopyCategoryDialog,
    AbstractDataViewDialog,
    UserListDialog,
    CategoryDetailsDialog,
    SimpleDataRow,
    ConfirmationDialog
  },
  i18n: { messages },
  mixins: [displayServiceMixin],
  data: () => ({
    searchField: '',
    datasetList: [],
    category: null,
    dataList: [],
    keywords: [],
    userList: [],
    filters: [],
    issues: [],
    dataByStatus: {},
    loading: false
  }),
  mounted () {
    EventBus.$on('closeAbstractDataView', () => this.loadData())
  },
  methods: {
    loadDataList () {
      this.$axios
        .get('data/get-all-for-category', {
          params: { id: this.category.id }
        })
        .then(response => {
          this.dataList = response.data
          this.addFilterParams()

          let groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
              (rv[x[key]] = rv[x[key]] || []).push(x)
              return rv
            }, {})
          }
          this.dataByStatus = groupBy(this.dataList, 'status')
        })
    },
    async loadData () {
      this.loading = true
      this.userList = []
      let id = this.$route.params.id
      await this.$axios
        .get('category/get', { params: { id: id } })
        .then(response => {
          this.category = response.data
          this.loadDataList()
        })
      await this.$axios
        .get('users/find-by-category', { params: { dataCategoryId: id } })
        .then(response => {
          this.userList = this.userList.concat(response.data)
        })
      await this.$axios
        .get('users/get-admin-list-by-project', { params: { projectId: this.selectedProject.id } })
        .then(response => {
          this.userList = this.userList.concat(response.data)
        })
      this.loading = false
      this.loadIssues()
    },
    loadIssues () {
      this.$axios.post('issue/find-pageable-search-result', {
        criteria: [{
          name: "categoryList",
          value: [this.category.id],
        }],
        page: 1,
        desc: "DESC",
        rowsPerPage: 10,
        sortBy: "id"
      }, {
        params: {
          projectId: this.selectedProject.id
        }
      })
        .then(res => this.issues = res.data.content)
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    addFilterParams () {
      let map = new Map()
      this.filters = []
      this.dataList.forEach(data => {
        data.keywords.forEach(keyword => {
          if (!map.has(keyword.id)) {
            map.set(keyword.id, keyword)
            keyword.active = false
            this.filters.push({ ...keyword })
          }
        })
      })
    },
    onSave () {
      this.loadData()
    },
    removeCategory () {
      this.$axios
        .post('category/delete', null, {
          params: { id: this.category.id }
        })
        .then(() => this.$router.push(this.linkBack))
    },
    goToIssues () {
      this.$router.push({ name: 'issue-list'})
      window.history.replaceState({category: this.category}, '', this.$route.fullPath)
    }
  },
  computed: {
    background () {
      if (!this.category) return
      let hash = 0
      for (let i = 0; i < this.category.name.length; i++) {
        hash = this.category.name.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash&hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return {
        'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))`
      }
    },
    activeFilters () {
      return this.filters.filter(it => it.active).map(it => it.id)
    },
    filteredDataList () {
      if (this.activeFilters.length) {
        return this.dataList.filter(
          it => this.activeFilters.every(
            filter => it.keywords.find(keyword => keyword.id === filter)
          )
        )
      } else {
        return this.dataList
      }
    },
    selectedProject () {
      return this.$store.getters.selectedProject
    },
    linkBack () {
      return `/project/${this.selectedProject.id}/home`
    },
    breadcrumbs () {
      return [
        {
          text: this.selectedProject.name,
          to: this.linkBack
        },
        {
          text: this.category.name,
          disabled: true
        }
      ]
    },
    progress () {
      if (this.dataByStatus['CHECKED'] && this.dataList) {
        return (this.dataByStatus['CHECKED'].length / this.dataList.length * 100).toFixed(1)
      }
      return 0
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    '$route' () {
      this.loadData()
    }
  }
}
</script>
