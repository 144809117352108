<template>
  <v-simple-table class="data-list-table" v-bind:style="fullWidth ? '' : 'width: 280px !important'">
    <tbody>
    <v-tooltip
      v-for="item in items"
      :key="item.id" :disabled="displayXS" :open-delay="selectedIssueId ? 1500 : 500" bottom color="black"
    >
      <template #activator="{on}">
        <tr :class="item.id === selectedIssueId ? 'selected-issue' : ''" v-on="on">
          <td
            class="inline-text pointer"
            style="max-width: 250px !important; text-align: left"
            @click="open(item)"
          >
            <v-avatar v-if="!fullWidth && !displayXS" :color="item.status.color" class="mr-2" size="10"
                      style="margin-top: -2px"/>
            <span>{{ item.name }}</span>
          </td>
          <td
            v-show="fullWidth && !displayXS"
            class="inline-text pointer"
            style="max-width: 250px !important; text-align: left"
            @click="open(item)"
          >
            <span>{{ item.poi ? item.poi.name || '' : '' }}</span>
            <span>{{ item.layerPoi ? getNameOfLayerPoi(item.layerPoi) : '' }}</span>
          </td>
          <td
            v-show="fullWidth && !displayXS"
            class="inline-text"
            style="max-width: 280px !important;"
            @click="open(item)"
          >
            <v-chip
              v-for="category in item.categoryList.filter(it => !it.isDefault)" :key="category.id" class="ma-1"
              color="deep-purple"
              label
              outlined
              small
              @click.stop="goToCategory(category)"
            >
              {{ category.name }}
            </v-chip>
          </td>
          <td v-show="fullWidth && !displayXS" class="inline-text pointer" @click="open(item)">
            {{ formatDate(item.creationDate) }}
          </td>
          <td v-show="fullWidth && !displayXS" class="inline-text pointer" @click="open(item)">
          <span v-if="item.performer">
            <user-avatar :user="item.performer" small/>
            <span style="white-space: nowrap; text-overflow: ellipsis">
              {{ item.performer.firstName + ' ' + item.performer.lastName }}
            </span>
          </span>
            <span v-else class="grey--text">
           {{ $t('notAssigned') }}
          </span>
          </td>
          <td v-show="fullWidth && !displayXS" class="pointer" style="text-align: right;" @click="open(item)">
            <v-avatar :color="item.status.color" class="mx-3" size="20"/>
          </td>
        </tr>
      </template>
      {{ item.name }}
    </v-tooltip>
    </tbody>
  </v-simple-table>
</template>

<script>
import UserAvatar from '@/components/utils/UserAvatar'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'
import { issueMixin } from '@/mixins/issue-mixin'
import messages from '../../componet-locale/issue-table/messages'
import { getNameOfLayerPoi } from '@/components/layer-poi/layer-poi-service'

export default {
  name: 'IssueTable',
  components: { UserAvatar },
  props: ['items', 'fullWidth'],
  mixins: [displayServiceMixin, issueMixin],
  i18n: { messages: messages },
  methods: {
    getNameOfLayerPoi: getNameOfLayerPoi,
    open (item) {
      this.$emit('open', item)
    },
    goToCategory (category) {
      this.$router.push(`/project/${this.$route.params.projectId}/category/${category.id}`)
    }
  },
  computed: {
    selectedIssueId () {
      return +this.$route.params.id
    }
  }
}
</script>

<style scoped>
.selected-issue {
  background-color: #ddd;
}
</style>
