<template>
  <v-dialog
    v-model="dialog"
    inset
    scrollable
    persistent
    width="750"
  >
    <v-card
      v-if="dialog"
      style="min-height: 80vh"
    >
      <v-card-title class="pb-0">
        <v-text-field
          v-model="issue.name"
          autofocus
          class="input-header"
          :placeholder="$t('name')"
        />
      </v-card-title>
      <v-card-text class="pt-2">
        <v-select
          placeholder="Тип задачи"
          class="input-header"
          v-if="templateList.length>0"
          :items="templateList"
          item-text="name"
          return-object
          v-model="issue.template"
        />

        <layer-poi-map-widget
          v-if="issue.layerPoi && mapWidget"
          :poi="issue.layerPoi"
        />

        <v-card-actions class="pa-0 mb-3">

          <d-date-picker
            :label="$t('completionDate')"
            v-model="issue.dueDate"
            dense
            hide-details
            outlined
            prepend-inner-icon="mdi-calendar"
          />

          <v-select
            prepend-inner-icon="person"
            v-model="issue.performer"
            :items="userList"
            dense
            class="ml-1"
            outlined
            :label="$t('executor')"
            return-object
            hide-details
            cache-items
          >
            <template v-slot:selection="{ item }">
              <user-avatar x-small :user="item"/>
              <span class="hide-text-overflow">
                {{ item.firstName + ' ' + item.lastName }}
              </span>
            </template>
            <template v-slot:item="{ item }">
              <user-avatar x-small :user="item" class="mr-2"/>
              <span class="hide-text-overflow">
                {{ item.firstName + ' ' + item.lastName }}
              </span>
            </template>
          </v-select>
        </v-card-actions>
        <category-picker
          v-model:selected="issue.categoryList"
          :project-id="+this.$route.params.projectId"
          hide-details
          class="mb-3"
        />

        <keywords-picker
          v-model:selected="issue.keywords"
          :project-id="+this.$route.params.projectId"
        />

        <issue-additional-fields
          v-if="issue.template"
          :issue="issue"
          :template="issue.template"
        />

        <div class="editor">
          <text-editor v-model="issue.description"/>
        </div>

      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog=false" outlined>{{ $t('button.cancel') }}</v-btn>
        <v-spacer/>
        <v-btn
          @click="save"
          color="primary"
          outlined
          :loading="loading"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/event-bus'
import TextEditor from '@/components/utils/TextEditor'
import UserAvatar from '@/components/utils/UserAvatar'
import messages from '../../componet-locale/new-issue-dialog/messages'
import IssueAdditionalFields from '@/components/issue/IssueAdditionalFields'
import LayerPoiMapWidget from '@/components/layer-poi/LayerPoiMapWidget'
import { mask } from 'vue-the-mask'
import DDatePicker from '@/components/utils/DDatePicker.vue'
import moment from 'moment'
import CategoryPicker from '@/components/utils/CategoryPicker.vue'
import KeywordsPicker from '@/components/utils/KeywordsPicker.vue'

export default {
  name: 'NewIssueDialog',
  components: { KeywordsPicker, CategoryPicker, DDatePicker, LayerPoiMapWidget, IssueAdditionalFields, UserAvatar, TextEditor },
  i18n: { messages: messages },
  directives: { mask },
  props: {
    mapWidget: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog: false,
    piker: false,
    loading: false,
    issue: null,
    userList: [],
    templateList: [],
    categoryList: []
  }),
  created () {
    this.$axios
      .get('project/get-users-by-project', {
        params: { id: this.selectedProject.id }
      })
      .then(response => this.userList = response.data)

    this.$axios
      .get('/category/get-all-for-project/', {
        params: { id: this.selectedProject.id }
      })
      .then(response => this.categoryList = response.data)

    this.$axios
      .get('/issue-template/get-all')
      .then(response => {
        this.templateList = response.data
      })
  },
  methods: {
    open (poi = null) {
      this.dialog = true
      this.loading = false
      this.issue = {
        name: '',
        poi: poi,
        additionalFields: [],
        description: { 'time': 1586976162351, 'blocks': [], 'version': '2.17.0' }
      }
    },
    createLayerPoiIssue (layerPoi) {
      this.issue = {
        name: '',
        layerPoi: { id: layerPoi.id },
        additionalFields: [],
        description: { 'time': 1586976162351, 'blocks': [], 'version': '2.17.0' }
      }
      this.dialog = true
    },
    createMapIssue (coordinates, mapId) {
      this.dialog = true
      this.issue = {
        name: '',
        map: { id: mapId },
        coordinates: coordinates,
        additionalFields: [],
        description: { 'time': 1586976162351, 'blocks': [], 'version': '2.17.0' }
      }
    },
    save () {
      this.loading = true
      this.issue.description = JSON.stringify(this.issue.description)
      this.issue.project = this.selectedProject
      if (this.issue.layerPoi) {
        this.issue.layerPoi.geometry = JSON.stringify(this.issue.layerPoi.geometry)
      }
      if( moment(this.issue.dueDate, 'DD.MM.YYYY', true).isValid() ){
        this.issue.dueDate = moment(this.issue.dueDate, 'DD.MM.YYYY').toDate().getTime()
      }
      this.$axios
        .post('issue/save', this.issue)
        .then(response => {
          this.$emit('on-save-issue')
          EventBus.$emit('showSuccessMessage', this.$t('taskCreated'))
          this.$emit('onSave', response.data)
          this.dialog = false
        })
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.loading = false)
    }
  }
}
</script>

<style scoped>
.editor {
  padding: 10px 12px;
  border: 1px #9e9e9e solid;
  border-radius: 5px;
}

.editor:hover {
  border: 1px #484848 solid;
}
</style>
