<template>
  <v-container>
    <div class="pa-10">
      <v-btn v-if="updateType === 'LOCAL'"
             :disabled="!lastBuildNumber"
             :loading="loading"
             class="mb-4"
             @click="updateAll">
        {{ $t('installLastVersion') }}
      </v-btn>
      <p v-else> {{ $t('updateDisabled') }} </p>
      <p v-bind:class="buildNumber === lastBuildNumber ? 'green--text' : 'red--text'">
        {{ $t('currentVersion') }}: {{ buildNumber }}
      </p>
      <p v-if="lastBuildNumber">
        {{ $t('availableVersion') }}: {{ lastBuildNumber }}
      </p>
      <p v-else>
        {{ $t('updateServerUnavailable') }}
      </p>
      <v-btn :loading="loading"
             class="mt-6"
             @click="reindex">
        {{ $t('rebuildIndex') }}
      </v-btn>
      <br/>
      <v-btn :loading="loading"
             class="mt-6"
             @click="removeFiles">
        {{ $t('removeUnusedFiles') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="deleteZwsCache">
        Очистить кэш для ZuluServer
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="widgetUpdate">
        {{ $t('widgetsUpdate') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="fixExternalTableFromBlock">
        {{ $t('fixExternalTableFromBlock') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="deleteRelatedWithMap">
        {{ $t('deleteRelatedWithMap') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="fixLayers">
        {{ $t('fixLayers') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="addMenuSettings">
        {{ $t('addMenuSettings') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="fixAttachedFilesError">
        {{ $t('fixAttachedFilesError') }}
      </v-btn>
      <br>
      <v-btn
        :loading="loading || lpsComputing"
        class="mt-6"
        @click="computeLps"
      >
        {{ $t('computeLps') }}
      </v-btn>
      <br>
      <v-btn
        :loading="loading || lpsComputing"
        class="mt-6"
        @click="setDefaultPredicatesInsteadOfNull"
      >
        {{ $t('setDefaultPredicatesInsteadOfNull') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="addDefaultProjectSettings">
        {{ $t('addDefaultProjectSettings') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="addPublicApiSettings">
        {{ $t('addPublicApiSettings') }}
      </v-btn>
      <br>
      <v-btn :loading="loading"
             class="mt-6"
             @click="fixIssueStatuses">
        {{ $t('fixIssueStatuses') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from '@/event-bus'
import messages from '@/componet-locale/update-page/messages'

export default {
  name: 'UpdatePage',
  data: () => ({
    loading: true,
    buildNumber: '...',
    lastBuildNumber: undefined,
    updateType: undefined,
    lpsComputing: false
  }),
  i18n: { messages },
  methods: {
    updateAll () {
      EventBus.$emit('showSuccessMessage', this.$t('updatePlanned'))
      this.loading = true
      this.$axios.post('/update/update-all') // it reloads model and gui
    },
    getUpdateType () {
      this.$axios
        .get('/update/get-update-type')
        .then(response => this.updateType = response.data)
    },
    reindex () {
      EventBus.$emit('showInfoMessage', this.$t('indexRebuildPlanned'))
      this.loading = true
      this.$axios
        .post('/full-text-search/reindex', null, { timeout: 600000 })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('indexRebuildPlanned')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('indexRebuildFailed')))
        .finally(() => this.loading = false)
    },
    setDefaultCategory () {
      EventBus.$emit('showSuccessMessage', this.$t('defaultCategoryPlanned'))
      this.loading = true
      this.$axios
        .post('/update/set-default-category')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('defaultCategoryDone')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('defaultCategoryFailed')))
        .finally(() => this.loading = false)
    },
    encryptMessages () {
      this.loading = true
      this.$axios
        .post('/update/encrypt-messages')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('encryptionDone')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('encryptionFailed')))
        .finally(() => this.loading = false)
    },
    addTokens () {
      EventBus.$emit('showSuccessMessage', this.$t('addTokenPlanned'))
      this.loading = true
      this.$axios
        .post('/update/add-tokens')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('tokenAdded')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('tokenNotAdded')))
        .finally(() => this.loading = false)
    },
    removeFiles () {
      EventBus.$emit('showInfoMessage', this.$t('deletionUnusedFilePlanned'))
      this.loading = true
      this.$axios
        .post('/update/delete-unused-files', null, { timeout: 600000 })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('fileRemoved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('fileNotRemoved')))
        .finally(() => this.loading = false)
    },
    deleteZwsCache () {
      this.loading = true
      this.$axios
        .post('/update/delete-zws-cache', null, { timeout: 600000 })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('fileRemoved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('fileNotRemoved')))
        .finally(() => this.loading = false)
    },
    removeMailTemplates () {
      this.loading = true
      this.$axios
        .post('/update/remove-mail-templates', null, { timeout: 600000 })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('fileRemoved')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('fileNotRemoved')))
        .finally(() => this.loading = false)
    },
    getBuildNumber () {
      this.$axios
        .get('/update/get-build-number')
        .then(result => {
          this.buildNumber = result.data
          // TODO move to special separate backend status check
          this.loading = false
        })
        .catch(() => EventBus.$emit('showInfoMessage', this.$t('updateNotAvailable')))
    },
    getLastBuildNumber () {
      this.$axios
        .get('/update/get-last-build-number')
        .then(result => this.lastBuildNumber = result.data)
    },
    widgetUpdate () {
      this.$axios.get('/widget/update-widget-api')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('widgetsUpdated')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    fixExternalTableFromBlock () {
      this.$axios.post('/et/fix-from-block')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('externalTableFixed')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    setDefaultPredicatesInsteadOfNull () {
      this.$axios.post('/et/set-default-predicates-instead-of-nulls')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('externalTableFixed')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    deleteRelatedWithMap () {
      this.$axios.post('/et/delete-related-with-map')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('relatedWithMapDeleted')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    fixLayers () {
      this.$axios.post('layer/fix-layers')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('fixed')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    addMenuSettings () {
      this.$axios.post('/settings/add-menu-settings')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('menuSettingsAdded')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    addDefaultProjectSettings () {
      this.$axios.post('/settings/add-default-project-settings')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('defaultProjectSettingsAdded')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    fixAttachedFilesError () {
      this.$axios.post('/files/fix-attached-files-public-error')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('attachedFilesFixed')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    computeLps () {
      this.lpsComputing = true
      this.$axios.get('/layer-poi/compute-lps', {
        timeout: 1000000
      })
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('lpsComputed')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
        .finally(() => this.lpsComputing = false)
    },
    addPublicApiSettings () {
      this.$axios.post('/settings/add-public-api-settings')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('publicApiSettingsAdded')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    },
    fixIssueStatuses(){
      this.$axios.post('/issue/fix-issue-statuses')
        .then(() => EventBus.$emit('showSuccessMessage', this.$t('issueStatusesFixed')))
        .catch(() => EventBus.$emit('showErrorMessage', this.$t('error')))
    }
  },
  created () {
    this.getBuildNumber()
    this.getLastBuildNumber()
    this.getUpdateType()
  }
}
</script>
