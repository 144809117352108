<template>
  <v-combobox
    :items="keywordList"
    :label="$t('keywords')"
    :value="selected"
    chips
    clearable
    dense
    item-text="value"
    multiple
    return-object
    v-bind="$attrs"
    @change="updateModel"
  >
    <template v-slot:selection="{item}">
      <v-chip
        class="my-1 hide-text-overflow"
        close
        label
        small
        @click:close="removeChips(item)"
      >
        {{ item.value }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
import messages from '@/componet-locale/keywords-picker/messages'

export default {
  name: "KeywordsPicker",
  i18n: { messages },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Array
    },
    projectId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    keywordList: []
  }),
  mounted () {
    this.getKeywordList()
  },
  methods: {
    getKeywordList () {
      this.$axios
        .get('/keyword/get-all-for-project/', { params: { id: this.projectId } })
        .then(response => {
          this.keywordList = response.data
        })
    },
    updateModel (value) {
      const selectedKeywords = JSON.parse(JSON.stringify(value))
      for (let i = 0; i < selectedKeywords.length; i++) {
        if (typeof selectedKeywords[i] === 'string') {
          let existingKeyword = this.keywordList.find(it => it.value === selectedKeywords[i])
          if (existingKeyword) {
            selectedKeywords[i] = existingKeyword
          } else {
            selectedKeywords[i] = { value: selectedKeywords[i] }
          }
        }
      }
      this.$emit('change', selectedKeywords)
    },
    removeChips (item) {
      this.updateModel(this.selected.filter(el => el.value !== item.value))
    }
  }
}
</script>
