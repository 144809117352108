<template>
  <v-data-table
    :footer-props="{itemsPerPageOptions: [15,50,100]}"
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :loading="busy"
    :options.sync="options"
    :server-items-length="totalElements"
    disable-sort
    item-key="id"
    style="max-height: 100vh"
  >

    <template v-slot:item.userEntity="{ item }">
      {{ item.userEntity.firstName + ' ' + item.userEntity.lastName }}
    </template>

    <template v-slot:item.abstractData="{ item }">
      <a
        v-if="item.abstractData"
        :href="`/project/${item.abstractData.project.id}/${item.abstractData.dataType.toLowerCase()}/${item.abstractData.id}`"
        target="_blank"
        @click="openData(item.abstractData)"
      >
        {{ item.abstractData.name }}
      </a>
      <a
        v-if="item.issue"
        :href="`/project/${item.issue.project.id}/issue/${item.issue.id}`"
        target="_blank"
      >
        {{ item.issue.name }}
      </a>
    </template>

    <template v-slot:item.categories="{ item }">
      <div v-if="item.abstractData">
        <v-chip
          v-for="category in item.abstractData.categoryList.filter(it => !it.isDefault)" class="ma-1"
          color="deep-purple"
          label
          outlined
          small
          @click="goToCategory(category)"
        >
          {{ category.name }}
        </v-chip>
      </div>
      <div v-if="item.issue">
        <v-chip
          v-for="category in item.issue.categoryList.filter(it => !it.isDefault)" class="ma-1"
          color="deep-purple"
          label
          outlined
          small
          @click="goToCategory(category)"
        >
          {{ category.name }}
        </v-chip>
      </div>
    </template>

    <template v-slot:item.date="{ item }">
      {{ formatDate(item.date) }}
    </template>

    <template v-slot:item.activityType="{ item }">
      {{ $t(item.activityType) }}
    </template>

  </v-data-table>
</template>

<script>
import messages from '@/componet-locale/user-activity/messages'

export default {
  name: "ChangesHistory",
  i18n: { messages },
  data: () => ({
    cancel: undefined,
    busy: false,
    totalElements: 0,
    options: {},
    items: [],
    itemsPerPage: 15
  }),
  methods: {
    loadData () {
      const CancelToken = this.$axios.CancelToken
      this.busy = true
      let self = this

      if (this.cancel) {
        this.cancel()
      }

      let criteria = this.headers
        .map(header => {
          return {
            name: header.value,
            value: header.find
          }
        })

      const { sortDesc, sortBy, page, itemsPerPage } = this.options

      let searchRequest = {
        page: page,
        desc: 'DESC',
        sortBy: 'id',
        rowsPerPage: itemsPerPage
      }

      if (sortBy.length > 0) {
        searchRequest.sortBy = sortBy[0]
        searchRequest.desc = sortDesc[0] ? 'DESC' : 'ASC'
      }
      this.$axios
        .post('admin/activity-log/find-pageable-modifications', searchRequest, {
          params: {
            projectId: this.$route.params.projectId,
          },
          cancelToken: new CancelToken(function executor (c) {
            self.cancel = c
          })
        })
        .then(response => {
          this.items = response.data.content
          this.totalElements = response.data.totalElements
        })
        .catch(thrown => {
          if (this.$axios.isCancel(thrown)) {
            console.error('Request canceled', thrown.message)
          } else {
            thrown ? console.error(thrown.toString()) : console.error('Unknown exception')
          }
        })
        .finally(() => this.busy = false)
    },
    goToCategory (category) {
      this.$router.push(`/project/${this.$route.params.projectId}/category/${category.id}`)
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('document'), value: 'abstractData' },
        { text: this.$t('activity'), value: 'activityType' },
        { text: this.$t('user'), value: 'userEntity' },
        { text: this.$t('categories'), value: 'categories' },
        { text: this.$t('dateAndTime'), value: 'date' }
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.loadData()
      },
      deep: true
    }
  }
}
</script>
