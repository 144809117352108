<template>
  <div :class="!displayXS ? 'pl-3': ''">
    <v-dialog
      fullscreen
      v-model="navDrawer"
    >
      <v-card>
        <v-card-title>
          {{$t('projectMenu')}}
          <v-spacer/>
          <v-btn
            @click="navDrawer=false"
            icon
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-list-item
          v-for="type in dataTypeList.filter(it => it.required)"
          :to="type.link"
          dense
        >
          <v-list-item-avatar>
            <v-icon>{{ type.icon }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(type.title) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-chip small v-if="projectDetails && projectDetails.numberOfDataByType[type.value]">
              {{ projectDetails.numberOfDataByType[type.value] }}
            </v-chip>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-dialog>

    <v-card :style="background" dark flat v-if="projectDetails">
      <v-card-title>
        <v-btn icon v-if="displayXS" @click="navDrawer=true">
          <v-icon>menu</v-icon>
        </v-btn>

        {{ selectedProject.name }}
        <v-subheader v-if="selectedProject.category">
          {{ selectedProject.category }}
        </v-subheader>
        <v-spacer/>
        <v-btn
          @click="$refs.confirmationDialog.open(selectedProject)"
          icon
          v-if="setAbility('ADMIN')"
        >
          <v-icon color="white">
            delete
          </v-icon>
        </v-btn>
        <v-btn
          @click="$refs.projectViewDialog.edit()"
          icon
          v-if="setAbility('ADMIN')">
          <v-icon color="white">
            edit
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        v-html="selectedProject.description"
        v-if="selectedProject.description"
        class="pt-0 white--text"/>

      <v-card-actions class="px-4 pb-3">
        <div v-show="!displayXS">
          <v-tooltip
            v-for="type in dataTypeList"
            top
            color="black"
          >
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                v-if="projectDetails && projectDetails.numberOfDataByType[type.value]"
                :to="type.link"
                class="mr-2 mb-2"
                color="white"
                label
                outlined
              >
                <v-icon left>{{ type.icon }}</v-icon>
                {{ projectDetails.numberOfDataByType[type.value] }}
              </v-chip>
            </template>
            <span>{{ $t(type.title) }}</span>
          </v-tooltip>
        </div>
        <v-spacer/>
        <v-btn
          @click="$refs.projectTimelineDlg.open()"
          text
        >
          <v-icon left>date_range</v-icon>
          Дорожная карта
        </v-btn>
        <v-btn @click="$refs.userListDialog.open()" text>
          <v-icon left>people_alt</v-icon>
          {{ projectDetails.userList.length }}
        </v-btn>
      </v-card-actions>

      <user-list-dialog
        ref="userListDialog"
        :user-list="projectDetails.userList"
      />
      <project-timeline-dialog ref="projectTimelineDlg"/>
    </v-card>

    <v-card
      outlined
      v-if="projectDetails && projectDetails.shortListOfUserTasks.length"
      class="mt-4"
    >
      <v-card-title class="pb-0">
        <span class="subtitle-1 pb-1">{{ $t('myTasks') }}</span>
        <v-spacer/>
        <v-btn
          to="issue"
          text
        >
          {{ $t('showAll') }}
        </v-btn>
      </v-card-title>

      <v-list dense subheader>
        <template v-for="(issue, index) in projectDetails.shortListOfUserTasks">
          <v-list-item :key="`issue-${index}`" @click="openIssue(issue, $event)">
            <v-list-item-avatar>
              <v-avatar :color="issue.status.color" class="mx-3" size="20"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ issue.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ getUserFullName(issue.reporter) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card>

    <project-view-dialog ref="projectViewDialog"/>
    <confirmation-dialog
      @confirm="removeProject"
      ref="confirmationDialog"
      :title="$t('removeConfirmation')"/>
  </div>
</template>

<script>
import messages from '../../componet-locale/project-dashboard/messages'
import { issueMixin } from '@/mixins/issue-mixin'
import ProjectViewDialog from '@/components/project/ProjectViewDialog'
import ConfirmationDialog from '@/components/utils/ConfirmationDialog'
import UserListDialog from '@/components/utils/UserListDialog'
import ProjectTimelineDialog from '@/components/project/ProjectTimelineDialog'
import projectMessages from '@/shared-locale/project/messages'
import { displayServiceMixin } from '@/mixins/dispay-service-mixin'

export default {
  name: 'ProjectDashboard',
  components: { ProjectTimelineDialog, UserListDialog, ConfirmationDialog, ProjectViewDialog},
  i18n: {
    messages,
    sharedMessages: projectMessages
  },
  data: () => ({
    navDrawer: false,
    projectDetails: null,
    statusList: [
      {
        id: 0,
        key: 'BACKLOG',
        color: 'grey'
      },
      {
        id: 1,
        key: 'OPEN',
        color: 'purple'
      },
      {
        id: 2,
        key: 'IN_PROGRESS',
        color: 'blue'
      },
      {
        id: 3,
        key: 'DONE',
        color: 'green'
      }
    ]
  }),
  mixins: [issueMixin, displayServiceMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$axios
        .get('project/get-project-details', {
          params: {id: this.selectedProject.id}
        })
        .then(response => {
          this.projectDetails = response.data
        })
    },
    removeProject() {
      this.$store.dispatch('removeProject', this.selectedProject.id)
    }
  },
  computed: {
    background() {
      let hash = 0
      for (let i = 0; i < this.selectedProject.name.length; i++) {
        hash = this.selectedProject.name.charCodeAt(i) + ((hash << 5) - hash)
        hash = hash & hash
      }
      hash = Math.abs(hash)
      const leftColor = hash % 360
      const rightColor = hash + 50
      return {
        'background-image': `linear-gradient(to bottom right, hsla(${leftColor}, 70%, 50%, 0.5), hsl(${rightColor}, 70%, 50%))`
      }
    }
  },
  watch: {
    '$route.params.projectId'() {
      this.init()
    }
  }
}
</script>
